export const humanReadableTimeDiff = (ms) => {
  const seconds = ms / 1000;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(seconds / 86400);
  const weeks = Math.floor(seconds / 604800);
  const months = Math.floor(seconds / 2629440);
  const years = Math.floor(seconds / 31553280);
  
  if (seconds <= 60) {
    return "Just now";
  } else if (minutes <= 60) {
    if (minutes === 1) {
      return "a minute ago";
    } else {
      return `${minutes} minutes ago`;
    }
  } else if (hours <= 24) {
    if (hours === 1) {
      return "an hour ago";
    } else {
      return `${hours} hours ago`;
    }
  } else if (days <= 7) {
    if (days === 1) {
      return "yesterday";
    } else {
      return `${days} days ago`;
    }
  } else if (weeks <= 5) {
    if (weeks === 1) {
      return "a week ago";
    } else {
      return `${weeks} weeks ago`;
    }
  } else if (months <= 12) {
    if (months === 1) {
      return "a month ago";
    } else {
      return `${months} months ago`;
    }
  } else {
    if (years === 1) {
      return "one year ago";
    } else {
      return `${years} years ago`;
    }
  }
};
