import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import { getSlugToken } from "../utils/content-utils";

import Img from "../components/Img";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import BlogComments from "../components/BlogComments";
import ShareSocial from "../components/ShareSocial";

import "../styles/blog-post.ext.scss";

export const BlogPostTemplate = ({
  slug,
  content,
  contentComponent,
  title,
  category,
  featuredImage,
  footerImages,
}) => {
  const PostContent = contentComponent || Content;

  const [expanded, setExpanded] = useState(false);

  return (
    <section className="blog-post container">
      <h1 className="post-title title has-text-centered">{title}</h1>
      <p className="category label-2 has-text-centered">{category}</p>
      <Img className="feature-image" image={featuredImage} />
      <ShareSocial />
      <div className="separator" />
      <div className={`post ${expanded ? "expand" : ""}`}>
        <PostContent className={`content-container`} content={content} />
      </div>
      {!expanded ? (
        <button className="load-more link" onClick={() => setExpanded(true)}>
          Read more here
        </button>
      ) : null}
      <div className="footer-images is-centered">
        {footerImages.map((footerImage, index) => (
          <div className="column">
            <Img
              key={index}
              image={footerImage.image}
              alt={footerImage.alt}
              className="image-wrapper"
            />
          </div>
        ))}
      </div>
      <BlogComments slug={slug} />
    </section>
  );
};

const BlogPost = ({ data }) => {
  const { post, categories: cEdges } = data;
  const categories = cEdges.edges.map((edge) => ({
    slug: edge.node.fields.slug,
    ...edge.node.frontmatter,
  }));

  const category = categories.find(
    (cat) => getSlugToken(cat.slug) === post.frontmatter.blog_category
  );
  const categoryName = category && category.title;

  return (
    <Layout
      title={post.frontmatter.title}
      description={post.frontmatter.description}
    >
      <BlogPostTemplate
        slug={getSlugToken(post.fields.slug)}
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        featuredImage={post.frontmatter.featuredimage}
        category={categoryName}
        footerImages={post.frontmatter.footerimages || []}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        blog_category
        featuredimage
        footerimages {
          alt
          image
        }
      }
    }

    categories: allMarkdownRemark(
      sort: { fields: [frontmatter___priority] }
      filter: { frontmatter: { type: { eq: "blog-category" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
