import React, { useEffect, useState } from "react";
import { humanReadableTimeDiff } from "../utils/date-utils";
import BlogForm from "./BlogForm";

// const FUNCTION_PATH = "http://localhost:9999/.netlify/functions/comments";
const FUNCTION_PATH = "/.netlify/functions/comments";

const BlogComments = ({ slug, formEnabled = false }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetch(`${FUNCTION_PATH}?n=comment-${slug}`).then((data) =>
      data.json().then((json) => {
        // console.debug(json);
        setComments(json);
      })
    );
  }, [slug]);

  const now = Date.now();

  return (
    <div className="blog-comments">
      <div className="comment-form container">
        <h1 className="has-text-left">Comments</h1>
        <BlogForm slug={slug} />
      </div>
      <div className="comments container">
        {comments.map((comment, index) => (
          <div className="comment" key={index}>
            <div className="columns">
              <p className="name label-1 is-one-fifth column">{comment.name}</p>
              <p className="time label-1 column">
                {humanReadableTimeDiff(
                  now - new Date(comment.created_at).getTime()
                )}
              </p>
            </div>
            <p className="content">{comment.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogComments;
