import React from "react";
import GImg from "gatsby-image";

const Img = ({
  image,
  className,
  objectFit = "cover",
  style = {},
  alt = "",
}) => {
  if (!!image && typeof image === "string") {
    return (
      <img
        className={className}
        src={image}
        alt={alt}
        style={{ objectFit: objectFit, ...style }}
      />
    );
  }

  if (!!image && image.childImageSharp && image.childImageSharp.fluid) {
    return (
      <GImg
        fluid={image.childImageSharp.fluid}
        className={className}
        alt={alt}
        imgStyle={{ objectFit: objectFit, ...style }}
      />
    );
  }

  return null;
};

export default Img;
