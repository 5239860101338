import React from "react";

const ShareSocial = () => {
  // Using third party service social9
  // https://social9.com/

  return (
    <div className="share-social">
      <p className="label-1">SHARE ON</p>
      <div className="s9-widget-wrapper"></div>
    </div>
  );
};

export default ShareSocial;
