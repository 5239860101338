import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { encode } from "../utils/url";
import Form from "./Form/Form";
import FormInputField from "./Form/FormInputField";
import FormSubmitButtonField from "./Form/FormSubmitButtonField";
import FormTextAreaField from "./Form/FormTextAreaField";

import "react-toastify/dist/ReactToastify.min.css";

const BlogForm = ({ slug }) => {
  const [formData, setFormData] = useState({});

  const reset = () => {
    const form = document.querySelector(".comment-form");
    const inputs = form.querySelectorAll("input, textarea");
    inputs.forEach((i) => (i.value = ""));
    setFormData({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    console.debug(formData);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        reset();
        toast.success("Sent. Your comments will be displayed once verified.");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed, please try again.");
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Form
      className="comment-form"
      name={`comment-${slug}`}
      action={`/blogs/comments/thanks`}
      honeypot="firstname"
      onHoneypotValueChange={handleChange}
      onSubmit={handleSubmit}
    >
      <FormInputField
        label="Full name"
        name="name"
        type="text"
        onChange={handleChange}
      />
      <FormTextAreaField
        label="Message"
        name="message"
        type="textarea"
        onChange={handleChange}
      />
      <FormSubmitButtonField>Post comment</FormSubmitButtonField>
      <ToastContainer />
    </Form>
  );
};

export default BlogForm;
